import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Переключение состояния меню
  };

  return (
    <div className="navbar-container">
      <div className="menu-toggle" onClick={toggleMenu}>
        Меню
      </div>

      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <div><a href="/">Главная</a></div>
        <div><a href="/FamilyShoot">Семейная фотосессия</a></div>
        <div><a href="/IndividualShoot">Индивидуальная съёмка</a></div>
        <div><a href="/ChildrenShoot">Детские фотосессии</a></div>
        <div><a href="/ExpressShoot">Экспресс-фотосессия</a></div>
        <div><a href="/LoveStory">Love story</a></div>
        <div><a href="/Photowalks">Фотопрогулки</a></div>
        <div><a href="/ImportantInformation">Важная информация</a></div>
      </div>
    </div>
  );
}

export default Navbar;
