import React from 'react';
import ImageSlider3 from '../components/slider3/slider3';

function Home() {
  return (
    <div className="App">
      <h3>Экспресс съёмка</h3>
      <p>2000 р/30 мин</p>
      <p>Идеальный формат для тех, кому не нужно много фотографий или хочется побыстрее.</p>
      <p>Что входит в стоимость :</p>
      <p>. Помощь в выборе локации</p>
      <p>. Советы по подбору образа</p>
      <p>. 20-25 фото в цветокоррекции</p>

      <ImageSlider3 folderName="ExpressShoot" />
    </div>
  );
}

export default Home;